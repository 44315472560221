import Container, { ContainerProps } from "@material-ui/core/Container"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { isMuiKey } from "../../../helpers"
import { switchBgColor } from "../../../theme/globalStyles"
import { AfbeeldingFragment, NieuwsAfbeeldingFragment } from "../../../__generated__/graphql-gatsby"
import {
  AfbeeldingFragment as PreviewAfbeeldingFragment,
  NieuwsAfbeeldingFragment as PreviewNieuwsAfbeeldingFragment,
} from "../../../__generated__/graphql-wp-sdk"

interface BgProps extends ContainerProps {
  bgColor: string
}
const Bg = styled(({ bgColor, ...props }) => <Container {...props} />)<BgProps>`
  ${({ bgColor }) => switchBgColor(bgColor)};
`

interface WrapperProps {
  padding: boolean
}
const Wrapper = styled(({ padding, ...props }) => <Container {...props} />)<
  WrapperProps & ContainerProps
>`
  padding-top: ${({ padding, theme }) => (padding ? theme.spacing(4) : 0)}px;
  padding-bottom: ${({ padding, theme }) => (padding ? theme.spacing(4) : 0)}px;
`

interface StyledContainerProps {
  maxWidth: string
}
const StyledContainer = styled(({ maxWidth, ...props }) => (
  <Wrapper {...props} />
))<StyledContainerProps>`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
`

const Img = styled.img`
  width: 100%;
`

interface ImgProps {
  block:
    | AfbeeldingFragment
    | NieuwsAfbeeldingFragment
    | PreviewAfbeeldingFragment
    | PreviewNieuwsAfbeeldingFragment
}
const Image: React.FC<ImgProps> = ({ block }) => {
  const { afbeelding, maxWidth, hasPadding } = block

  const backgroundColor =
    block.__typename === "WpPage_PageBuilder_PageBuilder_Afbeelding" ||
    block.__typename === "Page_PageBuilder_PageBuilder_Afbeelding"
      ? block.bgColor
      : "white"

  const gatsbyImageData =
    afbeelding?.__typename === "WpMediaItem"
      ? afbeelding?.localFile?.childImageSharp?.gatsbyImageData
      : null

  const imageURL =
    afbeelding?.__typename === "WpMediaItem"
      ? afbeelding?.localFile?.publicURL
      : afbeelding?.__typename === "MediaItem"
      ? afbeelding.mediaItemUrl
      : null

  const altText = String(afbeelding?.__typename === "WpMediaItem" ? afbeelding?.altText : "preview")

  const Wrap: React.FC = ({ children }) =>
    isMuiKey(maxWidth) ? (
      <Wrapper padding={hasPadding} maxWidth={maxWidth as MuiKey}>
        {children}
      </Wrapper>
    ) : (
      <StyledContainer padding={hasPadding} maxWidth={maxWidth}>
        {children}
      </StyledContainer>
    )

  if (!gatsbyImageData) {
    return (
      <Bg bgColor={backgroundColor} maxWidth={false} disableGutters>
        <Wrap>
          <Img src={String(imageURL)} alt={altText} />
        </Wrap>
      </Bg>
    )
  }

  return (
    <Bg bgColor={backgroundColor} maxWidth={false} disableGutters>
      <Wrap>
        <GatsbyImage image={gatsbyImageData} alt={altText} />
      </Wrap>
    </Bg>
  )
}

export default Image
